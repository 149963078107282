<!-- VaultLogic.svelte -->
<script context="module">
    import { writable } from 'svelte/store';
    import axios from 'axios';
    import { get } from 'svelte/store';
    import { page } from '@inertiajs/svelte';
    import toast from 'svelte-french-toast';
    export const items = writable([]);
    export const isGridView = writable(true);
    export const folderName = writable('');
    export const isDialogOpen = writable(false);
    export const selectedFolder = writable(null);
    export const currentFolderId = writable(null);
    export const parentFolderId = writable(null);
    export const rootItems = writable([]);
    export const subItems = writable([]);
    export const paginatedItems = writable([]);
    export const folderHistory = writable([]);
    export const draggedItem = writable(null);
    export const activeDropdown = writable(null);
    export const isDeleteDialogOpen = writable(false);
    export const folderToDelete = writable(null);
    export const previewImage = writable(null);
    export const isPreviewOpen = writable(false);
    export const currentPage = writable(1);
    export const hasMoreItems = writable(true);
    export const isFetching = writable(false);
    export const nextPageUrl = writable(null);
    export const subCurrentPage = writable(1);
    export const subHasMoreItems = writable(true);
    export const subIsFetching = writable(false);
    export const isMoveDialogOpen = writable(false);
    export const itemToMove = writable(null);
    export const selectedMoveFolder = writable(null);

    // image or vidoe click
    export const previewMedia = writable(null);
    export const previewMediaType = writable(null);
    export const isMediaPreviewOpen = writable(false);
    export const isMoveOpen = writable(false);

    // edit name
    export const isRenameDialogOpen = writable(false);
    export const itemToRename = writable(null);
    export const newItemName = writable('');
    export const moveDialogFolderId = writable(null);
    export const moveDialogHistory = writable([]);

    export function openMoveDialogFolder(folder) {
        if (!folder || !folder.id) return;
        isMoveDialogOpen.update((value) => true);
        let currentMoveId = get(moveDialogFolderId);
        if (currentMoveId) {
            moveDialogHistory.update((history) => [...history, currentMoveId]);
        }
        moveDialogFolderId.set(folder.id);
        fetchMoveDialogSubFolders(folder.id);
    }

    export function goBackMoveDialog() {
        let history = get(moveDialogHistory);

        if (history.length > 0) {
            const previousId = history.pop();
            moveDialogHistory.set(history);
            moveDialogFolderId.set(previousId);

            if (previousId) {
                fetchMoveDialogSubFolders(previousId);
            }
        } else {
            moveDialogFolderId.set(null);
        }
        isMoveDialogOpen.set(true);
    }

    export async function fetchDataPage() {
        if (get(isFetching) || !get(hasMoreItems)) return;

        isFetching.set(true);
        try {
            let nextPage = get(currentPage) + 1;
            let url = `/my/vault/items?page=${nextPage}`;
            const response = await axios.get(url);
            if (!response.data || !response.data.items || !response.data.items.data) {
                console.error('🚨 Error: No valid data found in response!');
                return;
            }

            const { data: newItems = [], next_page_url } = response.data.items;
            if (newItems.length === 0) {
            }
            rootItems.update((items) => {
                const existingIds = new Set(items.map((item) => item.id));
                const uniqueNewItems = newItems.filter((item) => !existingIds.has(item.id));
                paginatedItems.update((items) => [...items, ...uniqueNewItems]);
                return [...items, ...uniqueNewItems];
            });
            currentPage.set(nextPage);
            if (next_page_url) {
                hasMoreItems.set(true);
            } else {
                hasMoreItems.set(false);
            }
        } catch (error) {
            console.error('❌ Error fetching data:', error);
        } finally {
            isFetching.set(false);
        }
    }

    export function openPreview(mediaUrl, mediaType) {
        console.log(mediaUrl, 'media url');
        if (!mediaUrl) {
            console.error('⚠️ No media URL provided!');
            return;
        }

        previewMedia.set(mediaUrl);
        previewMediaType.set(mediaType);
        isMediaPreviewOpen.set(true);
    }

    export function truncateText(text, length = 10) {
        if (!text) return '';
        return text.length > length ? text.substring(0, length) + '...' : text;
    }

    export function openFolderContextMenu(event, item) {
        event.preventDefault();
        activeDropdown.set(item.id);
        document.addEventListener('click', closeDropdown);
    }

    export function closeDropdown(event) {
        if (!event?.target?.closest?.('.dropdown-menu')) {
            activeDropdown.set(null);
            document.removeEventListener('click', closeDropdown);
        }
    }

    export function onDragStart(event, item) {
        draggedItem.set(item);
        event.dataTransfer.setData('text/plain', item.id);
    }

    export async function onDrop(event, targetFolder) {
        event.preventDefault();

        let currentDraggedItem;
        draggedItem.subscribe((val) => (currentDraggedItem = val))();
        if (!currentDraggedItem || currentDraggedItem.id === targetFolder.id) return;
        try {
            const payload = {
                item_id: currentDraggedItem.id,
                parent_id: targetFolder.id,
            };
            const response = await axios.post('/my/vault/move', payload);
            let localRootItems, localSubItems, localCurrentFolderId;
            rootItems.subscribe((val) => (localRootItems = val))();
            subItems.subscribe((val) => (localSubItems = val))();
            currentFolderId.subscribe((val) => (localCurrentFolderId = val))();

            if (localCurrentFolderId === currentDraggedItem.folder_id) {
                localSubItems = localSubItems.filter((i) => i.id !== currentDraggedItem.id);
                subItems.set(localSubItems);
            } else {
                localRootItems = localRootItems.filter((i) => i.id !== currentDraggedItem.id);
                rootItems.set(localRootItems);
            }

            currentDraggedItem.folder_id = targetFolder.id;
            localSubItems = [currentDraggedItem, ...localSubItems];
            subItems.set(localSubItems);

            console.log(currentDraggedItem.folder_id, 'draggedItem.folder_id');

            if (localCurrentFolderId) {
                await fetchSubFolders(localCurrentFolderId);
            }

            draggedItem.set(null);
        } catch (error) {
            console.error('Error moving item:', error);
            toast.error('Error fetching subfolders:');
        }
    }

    export function getFolderIdFromRoute() {
        let urlPath;
        page.subscribe((val) => {
            urlPath = val.url?.pathname || '';
        })();

        const pathParts = urlPath.split('/');
        return pathParts.length > 2 ? pathParts[2] : null;
    }

    export async function fetchSubFolders(folderId) {
        if (!folderId || get(subIsFetching) || !get(subHasMoreItems)) return;

        subIsFetching.set(true);

        try {
            let nextPage = get(subCurrentPage);
            let url = `/my/vault/fetchFolder/${folderId}?page=${nextPage}`;
            const response = await axios.get(url);
            if (!response.data || !response.data.items) {
                console.error('🚨 No valid data found in response!');
                return;
            }

            const { items: newItems = [], paginationData } = response.data;
            if (newItems.length === 0) {
                console.warn('⚠️ No new subfolder items in API response!');
            }

            subItems.update((items) => {
                const existingIds = new Set(items.map((item) => item.id));
                const uniqueNewItems = newItems.filter((item) => !existingIds.has(item.id));
                return [...items, ...uniqueNewItems];
            });
            subCurrentPage.update((n) => n + 1);
            if (paginationData?.next_page_url) {
                subHasMoreItems.set(true);
            } else {
                subHasMoreItems.set(false);
            }
        } catch (error) {
            console.error('❌ Error fetching subfolder items:', error);
        } finally {
            subIsFetching.set(false);
        }
    }

    export function openCreateFolderDialog() {
        folderName.set('');
        isDialogOpen.set(true);
    }

    export function createFolder() {
        let localFolderName, localCurrentFolderId;
        folderName.subscribe((val) => (localFolderName = val))();
        currentFolderId.subscribe((val) => (localCurrentFolderId = val))();

        if (!localFolderName.trim()) {
            toast.error('Please enter a valid folder name.');
            return;
        }

        axios
            .post('/my/vault/createFolder', {
                name: localFolderName.trim(),
                folder_id: localCurrentFolderId ?? null,
                parent_id: localCurrentFolderId,
            })
            .then((response) => {
                const newFolder = {
                    id: response?.data?.folder?.id,
                    name: localFolderName.trim(),
                    type: 'folder',
                    created: new Date().toLocaleString('en-GB'),
                    fileUrl: '/svg/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.webp',
                };
                let localRootItems, localSubItems;
                rootItems.subscribe((val) => (localRootItems = val))();
                subItems.subscribe((val) => (localSubItems = val))();

                if (localCurrentFolderId) {
                    subItems.set([newFolder, ...localSubItems]);
                } else {
                    rootItems.set([newFolder, ...localRootItems]);
                }
                items.set([...localRootItems, ...localSubItems]);
                isDialogOpen.set(false);
                toast.success(`Folder created successfully.`);
            })
            .catch((error) => {
                console.error('Error creating folder:', error);
                toast.error('Failed to create folder. Please try again.');
            });
    }

    export function handleFileUpload() {
        let localCurrentFolderId;
        currentFolderId.subscribe((val) => (localCurrentFolderId = val))();

        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*, video/*, application/pdf, .zip';

        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            if (!file) return;

            const formData = new FormData();
            formData.append('file', file);
            if (localCurrentFolderId) {
                formData.append('folder_id', localCurrentFolderId);
            }

            try {
                const response = await axios.post('/my/vault/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
                if (!response.data || !response.data.attachmentID) {
                    throw new Error('Invalid API response: attachmentID is missing');
                }
                const newItem = {
                    id: response.data.attachmentID,
                    name: file.name,
                    type: response.data.type,
                    media_type: response.data.type,
                    folder_id: localCurrentFolderId,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    attachment: {
                        path: response.data.path,
                        thumbnail: response.data.thumbnail,
                    },
                };

                let localRootItems, localSubItems;
                rootItems.subscribe((val) => (localRootItems = [...val]))();
                subItems.subscribe((val) => (localSubItems = [...val]))();

                if (localCurrentFolderId) {
                    subItems.set([newItem, ...localSubItems]);
                } else {
                    rootItems.set([newItem, ...localRootItems]);
                }

                items.set([...localRootItems, ...localSubItems]);

                toast.success(`File uploaded successfully.`);
            } catch (error) {
                console.error('Error uploading file:', error.message);
                toast.error('File upload failed. Please try again.');
            }
        };

        fileInput.click();
    }

    export function editItem(item) {
        alert(`Edit functionality for: ${item.name}`);
        activeDropdown.set(null);
    }

    export function openFolder(folder) {
        let localFolderHistory = get(folderHistory);
        let localCurrentFolderId = get(currentFolderId);

        if (localCurrentFolderId) {
            localFolderHistory.push(localCurrentFolderId);
            folderHistory.set(localFolderHistory);
        }

        currentFolderId.set(folder.id);
        subCurrentPage.set(1);
        subHasMoreItems.set(true);
        subItems.set([]);

        window.history.pushState({}, '', `/my/vault/fetchFolders/${folder.id}`);
        fetchSubFolders(folder.id);
    }

    export function openFolderMove(folder, isMoal = false) {
        if (isMoal) {
            openMoveDialogFolder(folder);
        } else {
            let localFolderHistory = get(folderHistory);
            let localCurrentFolderId = get(currentFolderId);

            if (localCurrentFolderId) {
                localFolderHistory.push(localCurrentFolderId);
                folderHistory.set(localFolderHistory);
            }

            currentFolderId.set(folder.id);
            subCurrentPage.set(1);
            subHasMoreItems.set(true);
            subItems.set([]);

            window.history.pushState({}, '', `/my/vault/fetchFolders/${folder.id}`);
            fetchSubFolders(folder.id);
        }
    }

    export function goBack() {
        let localFolderHistory;
        folderHistory.subscribe((val) => (localFolderHistory = val))();

        if (localFolderHistory.length > 0) {
            const previousFolderId = localFolderHistory.pop();
            folderHistory.set(localFolderHistory);

            currentFolderId.set(previousFolderId);
            window.history.pushState({}, '', `/my/vault/fetchFolders/${previousFolderId}`);
            fetchSubFolders(previousFolderId);
        } else {
            currentFolderId.set(null);
            window.history.pushState({}, '', '/my/vault');
            subItems.set([]);
        }
    }

    export function openDeleteDialog(item) {
        folderToDelete.set(item);
        isDeleteDialogOpen.set(true);
    }

    export async function confirmDelete() {
        let localFolderToDelete;
        folderToDelete.subscribe((val) => (localFolderToDelete = val))();

        if (!localFolderToDelete) return;

        try {
            const deleteUrl =
                localFolderToDelete.type === 'folder' ? '/my/vault/delete/folder' : '/my/vault/delete/file';

            await axios.delete(deleteUrl, {
                data: { id: localFolderToDelete.id },
            });

            rootItems.update((items) => {
                const index = items.findIndex((i) => i.id === localFolderToDelete.id);
                if (index !== -1) items.splice(index, 1);
                return [...items];
            });

            subItems.update((items) => items.filter((i) => i.id !== localFolderToDelete.id));
            toast.success(
                `${localFolderToDelete.type} "${localFolderToDelete.name || localFolderToDelete.filename}" deleted successfully`
            );

            let localCurrentFolderId;
            currentFolderId.subscribe((val) => (localCurrentFolderId = val))();

            if (localCurrentFolderId) {
                console.log(`📂 Refreshing folder: ${localCurrentFolderId}`);
                await fetchSubFolders(localCurrentFolderId);
            }
        } catch (error) {
            console.error(`❌ Error deleting ${localFolderToDelete.type}:`, error);
            toast.error(`Failed to delete ${localFolderToDelete.type}. Please try again.`);
        } finally {
            isDeleteDialogOpen.set(false);
            folderToDelete.set(null);
        }
    }

    async function fetchMoveDialogSubFolders(folderId) {
        if (!folderId) return;

        try {
            const response = await axios.get(`/my/vault/fetchFolder/${folderId}`);
            if (response.data && response.data.items) {
                const folderItems = response?.data?.items.filter((item) => item.type === 'folder');
                subItems.set(folderItems);
            }
        } catch (error) {
            console.error('❌ Error fetching move dialog subfolders:', error);
            toast.error('Failed to load folders.');
        }
    }

    export async function moveItem() {
        const item = get(itemToMove);
        const destinationFolder = get(selectedMoveFolder);

        if (!item) {
            toast.error('No item selected for moving.');
            return;
        }
        if (destinationFolder?.id === item?.id) {
            toast.error('You cannot move a folder to its current location.');
            return;
        }
        if (!item.parent_id && !destinationFolder.id) {
            toast.error('Please select a distination folder');
            return;
        }

        try {
            const payload = { item_id: item.id };
            if (destinationFolder?.id) {
                payload.parent_id = destinationFolder.id;
            }

            await axios.post('/my/vault/move', payload);

            toast.success(
                `"${item.name}" moved successfully${destinationFolder ? ` to "${destinationFolder.name}"` : ''}`
            );

            rootItems.update((items) => items.filter((i) => i.id !== item.id));
            subItems.update((items) => items.filter((i) => i.id !== item.id));
            isMoveDialogOpen.set(false);
            itemToMove.set(null);
            selectedMoveFolder.set(null);
        } catch (error) {
            console.error('❌ Error moving item:', error);
            toast.error('Failed to move item.');
        }
    }

    export function openMoveDialog(item) {
        itemToMove.set(item);
        selectedMoveFolder.set(null);
        isMoveDialogOpen.set(true);
        isMoveOpen.set(true);
    }

    export function selectMoveFolder(folder) {
        if (!folder) return;
        selectedMoveFolder.set(folder);
    }

    export function openRenameDialog(item) {
        if (!item) return;

        let itemName = item.name;

        // If it's an image or video, use attachment filename instead
        if (item.media_type === 'image' || item.media_type === 'video') {
            itemName = item.attachment?.filename || item.name;
        }

        itemToRename.set(item);
        newItemName.set(itemName);
        isRenameDialogOpen.set(true);
    }

    export async function renameItem() {
        let localItem, localNewItemName;
        itemToRename.subscribe((val) => (localItem = val))();
        newItemName.subscribe((val) => (localNewItemName = val))();

        if (!localItem || !localNewItemName.trim()) {
            toast.error('Please enter a valid name.');
            return;
        }

        try {
            const renameResponse = await axios.post('/my/vault/rename', {
                id: localItem.id,
                name: localNewItemName.trim(),
            });

            if (renameResponse.data.success) {
                rootItems.update((items) =>
                    items.map((i) => (i.id === localItem.id ? { ...i, name: localNewItemName.trim() } : i))
                );
                subItems.update((items) =>
                    items.map((i) => (i.id === localItem.id ? { ...i, name: localNewItemName.trim() } : i))
                );

                toast.success(`"${localItem.name}" renamed to "${localNewItemName.trim()}"`);
                isRenameDialogOpen.set(false);
                itemToRename.set(null);
            } else {
                throw new Error('Rename failed.');
            }
        } catch (error) {
            console.error('Error renaming item:', error);
            toast.error('Failed to rename item. Please try again.');
        }
    }
</script>
